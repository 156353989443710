import React from 'react';
import {
  IconApps,
  IconArchive,
  IconBook,
  IconCalendar,
  IconCommand,
  IconDashboard,
  IconDragDotVertical,
  IconDriveFile,
  IconFindReplace,
  IconGift,
  IconHome,
  IconImage,
  IconList,
  IconMenu,
  IconNav,
  IconPalette,
  IconRobot,
  IconSearch,
  IconSettings,
  IconSound,
  IconStorage,
  IconSun,
  IconTags,
  IconUser,
  IconUserGroup,
  IconWechat,
} from '@arco-design/web-react/icon';

export const defaultRoute = 'index';

export const routes = [
  {
    name: '首页',
    key: 'index',
    icon: <IconHome />,
    componentPath: 'index',
  },
  {
    name: "销宴推广通仪表盘",
    key: "banquetStatistics",
    icon: <IconDashboard />,
    componentPath: "banquetStatistics/index",
  },
  {
    name: "数据统计",
    key: "statistics",
    icon: <IconDashboard />,
    componentPath: "statistics/index",
  },
  {
    name: '运营数据',
    key: 'operationalData',
    icon: <IconDashboard />,
    children: [
      {
        name: '版本与套餐',
        key: 'operationalData/versionsAndPackages',
        icon: <IconNav />,
        componentPath: 'operationalData/versionsAndPackages',
      },
      {
        name: '活跃监测',
        key: 'operationalData/activityMonitoring',
        icon: <IconNav />,
        children: [
          {
            name: '数据图表',
            key: 'operationalData/activityMonitoring/chart',
            icon: <IconDashboard />,
            componentPath: 'operationalData/activityMonitoring/chart',
          },
          {
            name: '不活跃名单',
            key: 'operationalData/activityMonitoring/list',
            icon: <IconNav />,
            componentPath: 'operationalData/activityMonitoring/list',
          },
        ]
      },
      {
        name: '质量监测',
        key: 'operationalData/qualityMonitoring',
        icon: <IconNav />,
        children: [
          {
            name: '数据图表',
            key: 'operationalData/qualityMonitoring/chart',
            icon: <IconDashboard />,
            componentPath: 'operationalData/qualityMonitoring/chart',
          },
          {
            name: '订单数低名单',
            key: 'operationalData/qualityMonitoring/list',
            icon: <IconNav />,
            componentPath: 'operationalData/qualityMonitoring/list',
          },
        ]
      },
      {
        name: '续费监测',
        key: 'operationalData/renewalMonitoring',
        icon: <IconNav />,
        componentPath: 'operationalData/renewalMonitoring/currentMonth',
        // children: [
        //   {
        //     name: '需续费商家名单',
        //     key: 'operationalData/renewalMonitoring/currentMonth',
        //     icon: <IconNav />,
        //     componentPath: 'operationalData/renewalMonitoring/currentMonth',
        //   },
        //   {
        //     name: '下月需续费商家',
        //     key: 'operationalData/renewalMonitoring/nextMonth',
        //     icon: <IconNav />,
        //     componentPath: 'operationalData/renewalMonitoring/nextMonth',
        //   },
        // ]
      },
      {
        name: '营销短信消耗',
        key: 'operationalData/marketingSmsMonitoring',
        icon: <IconNav />,
        componentPath: 'operationalData/marketingSmsMonitoring',
      },
    ],
  },
  {
    name: '店铺列表',
    key: 'shop/index',
    icon: <IconArchive/>,
    componentPath: 'shop/list/index',
  },
  {
    name: '服务投诉列表',
    key: 'complaint/index',
    icon: <IconArchive />,
    componentPath: 'complaint/index',
  },
  {
    name: '个人版店铺列表',
    key: 'personShop/index',
    icon: <IconArchive/>,
    componentPath: 'personShop/index',
  },
  {
    name: '订单数据',
    key: 'orderData',
    icon: <IconNav/>,
    children: [
      {
        name: '预订名片订单',
        key: 'orderData/reserveOnline',
        icon: <IconNav/>,
        componentPath: 'orderData/reserveOnline',
      },
      /*{
        name: '预定名片订单',
        key: 'orderData/reserveOnline',
        icon: <IconNav/>,
        componentPath: 'orderData/reserveOnline'
      },*/
      {
        name: '高德订单',
        key: 'orderData/amap',
        icon: <IconNav />,
        componentPath: 'orderData/amap',
      },
      {
        name: '销宴推广通订单',
        key: 'orderData/promotion',
        icon: <IconNav />,
        componentPath: 'orderData/promotion',
      },
      {
        name: '推广通订单申诉',
        key: 'orderData/promotionAppeal',
        icon: <IconNav />,
        componentPath: 'orderData/promotionAppeal',
      },
    ],
  },
  {
    name: '商品套餐服务',
    key: 'packageData',
    icon: <IconGift/>,
    children: [
      {
        name: '套餐管理',
        key: 'package',
        icon: <IconCommand />,
        componentPath: 'package',
      },
    ],
  },
  {
    name: '充值管理',
    key: 'recharge',
    icon: <IconFindReplace />,
    children: [
      {
        name: "充值记录",
        key: "rechargeRecord",
        icon: <IconFindReplace/>,
        componentPath: 'rechargeRecord',
      },
      {
        name: "充值套餐",
        key: "rechargePackage",
        icon: <IconFindReplace/>,
        componentPath: 'rechargePackage',
      },
      {
        name: "支付记录",
        key: "cancellationRecord",
        icon: <IconFindReplace />,
        componentPath: 'cancellationRecord',
      }
    ]
  },
  {
    name: '小程序内容',
    key: 'miniProgram',
    icon: <IconWechat />,
    children: [
      {
        name: '文案管理',
        key: 'official',
        icon: <IconPalette />,
        children: [
          {
            name: '文案管理',
            key: 'official/officialPlans',
            icon: <IconDriveFile />,
            componentPath: 'offical/officalPlans',
          },
          {
            name: '文案分类',
            key: 'official/officialGroup',
            icon: <IconStorage />,
            componentPath: 'offical/officalGroup',
          },
        ],
      },
      {
        name: '广告管理',
        key: 'advertising',
        icon: <IconImage />,
        componentPath: 'advertising',
      },
      {
        name: '系统公告',
        key: 'afficheManage',
        icon: <IconSound />,
        componentPath: 'afficheManage',
      },
    ],
  },
  {
    name: '营销管理',
    key: 'marketing',
    icon: <IconList />,
    children: [
      {
        name: '申请记录',
        key: 'shop/merchant',
        icon: <IconList />,
        componentPath: 'shop/merchant',
      },
      // {
      //   name: '客户维护',
      //   key: '',
      //   icon: <IconList />,
      //   componentPath: "",
      // },
    ]
  },
  {
    name: '系统配置',
    key: 'systemSetting',
    icon: <IconSettings />,
    children: [
      /*{
        name: '基础配置',
        key: '',
        icon: <IconList />,
        componentPath: '',
      },*/
      {
        name: '系统设置',
        key: 'systemConfig',
        icon: <IconSun />,
        componentPath: 'systemConfig',
      },
      {
        name: '节假日管理',
        key: 'calendar',
        icon: <IconCalendar />,
        componentPath: 'holidayManage',
      },
      {
        name: 'APP管理',
        key: 'AppVersion',
        icon: <IconApps />,
        componentPath: 'appVersion',
      },
      {
        name: '餐厅类型',
        key: 'restaurantTypeManage',
        icon: <IconDragDotVertical />,
        componentPath: 'restaurantTypeManage',
      },
      {
        name: '菜系管理',
        key: 'menuTypeManage',
        icon: <IconBook />,
        componentPath: 'menuTypeManage',
      },
      {
        name: '微信用户管理',
        key: 'wechat_users',
        icon: <IconUserGroup />,
        componentPath: 'wechatAccount/users',
      },
      {
        name: '客服管理',
        key: 'users',
        icon: <IconRobot />,
        children: [
          {
            name: '客服管理',
            key: 'users',
            icon: <IconRobot/>,
            componentPath: 'QyWeixinUsers/index',
          },
          {
            name: "支付查询",
            key: "payment",
            icon: <IconSearch/>,
            componentPath: "QyWeixinUsers/payment",
            ignore: true
          }
        ]
      },
      {
        name: "公众号菜单管理",
        key: "wechat_account_menus",
        icon: <IconMenu />,
        componentPath: "wechatAccount/menus",
      },
    ],
  },
  {
    name: '学习中心',
    key: 'tutorial',
    icon: <IconFindReplace/>,
    children: [
      {
        name: '个人版',
        key: 'tutorial/person',
        icon: <IconList/>,
        children: [
          {
            name: '分类管理',
            key: 'tutorial/person/category',
            icon: <IconList/>,
            componentPath: 'tutorial/person/category',
          },
          {
            name: '内容管理',
            key: 'tutorial/person/content',
            icon: <IconList/>,
            componentPath: 'tutorial/person/content',
          },
        ]
      },
      {
        name: '高级版',
        key: 'tutorial/shop',
        icon: <IconList/>,
        children: [
          {
            name: '分类管理',
            key: 'tutorial/shop/category',
            icon: <IconList/>,
            componentPath: 'tutorial/shop/category',
          },
          {
            name: '内容管理',
            key: 'tutorial/shop/content',
            icon: <IconList/>,
            componentPath: 'tutorial/shop/content',
          },
        ]
      },
    ]
  },
  // {
  //   name: "接口管理",
  //   key: "routes",
  //   icon: <IconList/>,
  //   children: [
  //     {
  //       name: "接口管理",
  //       key: "routes/apiRoutes",
  //       icon: <IconList/>,
  //       componentPath: "routes/apiRoutes",
  //     },
  //     {
  //       name: "店铺接口版本管理",
  //       key: "routes/shopRoutes",
  //       icon: <IconList/>,
  //       componentPath: "routes/shopRoutes",
  //     }
  //   ],
  // },
  {
    name: "找人订管理",
    key: "personnelManagement",
    icon: <IconUserGroup/>,
    children: [
      {
        name: "标签管理",
        key: "personnelManagement/tags",
        icon: <IconTags/>,
        componentPath: "personnelManagement/tags"
      },
      {
        name: "人员管理",
        key: "personnelManagement/index",
        icon: <IconUserGroup/>,
        componentPath: "personnelManagement/index"
      }
    ]
  },
  {
    name: "账号管理",
    key: "accountManagement",
    icon: <IconUser/>,
    componentPath: "accountManagement"
  },
  // {
  //   name: "服务商管理",
  //   key: "facilitatorManagement",
  //   icon: <IconUser/>,
  //   children: [
  //     {
  //       name: "服务商列表",
  //       key: "facilitatorManagement/index",
  //       icon: <IconTags/>,
  //       componentPath: "facilitatorManagement/index"
  //     },
  //     {
  //       name: "提现管理",
  //       key: "facilitatorManagement/withdrawManagement",
  //       icon: <IconTags/>,
  //       componentPath: "facilitatorManagement/withdrawManagement"
  //     },
  //     {
  //       name: "退款管理",
  //       key: "facilitatorManagement/refundManagement",
  //       icon: <IconTags/>,
  //       componentPath: "facilitatorManagement/refundManagement"
  //     },
  //     {
  //       name: "手动新增订单管理",
  //       key: "facilitatorManagement/orderManagement",
  //       icon: <IconTags/>,
  //       componentPath: "facilitatorManagement/orderManagement"
  //     },
  //     {
  //       name: "申请绑定客户管理",
  //       key: "facilitatorManagement/bindingManagement",
  //       icon: <IconTags/>,
  //       componentPath: "facilitatorManagement/bindingManagement"
  //     },
  //   ]
  //   // componentPath: "facilitatorManagement" 
  // },
];
