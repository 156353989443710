/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-07-08 18:04:51
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-10-11 16:52:56
 * @Description: 
 */
import request, {baseURL} from "@/utils/request";
//登录相关
const api = {
  login: data => request.post(`/server_admin/login`, data),
  checkLogin: () => request.get("/server_admin/login/check"),
  logout: () => request.get("/server_admin/logout"),
  loginFeiShu: data => request.post('/server_admin/feishuLogin', data),
};

//店铺相关
const shop = {
  list: data => request.get("/server_admin/shop/list", {params: data}),
}

//高德订单相关
const amap = {
  list: (data = {}) => request.get("/server_admin/amap/lists", {params: data}),
  export: (data = {}) => request.getUri({
    url: `${baseURL}/server_admin/amap/export`,
    params: {...data, token: localStorage.getItem("token")},
    method: "get"
  }),
  promotionRefund: (data = {}) => request.post("/server_admin/promotion/refund", data),//销宴通申诉退款
  refundSuccess: (data = {}) => request.post("/server_admin/promotion/refundSuccess", data),//销宴通申诉退款通过
  refundRefuse: (data = {}) => request.post("/server_admin/promotion/refundRefuse", data),//销宴通申诉退款拒绝
};
//高德订单相关
const largeDataScreen = {
  shopNumber: (data = {}) => request.get("/open/shopNumber", {params: data}),
  countOrder: (data = {}) => request.get("/open/countOrder", {params: data}),
  miniuserCount: (data = {}) => request.get("/open/miniuserCount", {params: data}),
  newShop: (data = {}) => request.get("/open/newShop", {params: data}),
  todayOrderNumber: (data = {}) => request.get("/open/todayOrderNumber", {params: data}),
  weekAddShopInfo: (data = {}) => request.get("/open/weekAddShopInfo", {params: data}),
  weekAddOrderInfo: (data = {}) => request.get("/open/weekAddOrderInfo", {params: data}),
  todayNewUser: (data = {}) => request.get("/open/todayNewUser", {params: data}),
  weekAddUserInfo: (data = {}) => request.get("/open/weekAddUserInfo", {params: data}),
  todayMoney: (data = {}) => request.get("/open/todayMoney", {params: data}),
  weekMoneyInfo: (data = {}) => request.get("/open/weekMoneyInfo", {params: data}),
  todaySms: (data = {}) => request.get("/open/todaySms", {params: data}),
  weekSms: (data = {}) => request.get("/open/weekSms", {params: data}),
  todayActiveShops: (data = {}) => request.get("/open/todayActiveShops", {params: data}),
  todayActive: (data = {}) => request.get("/open/todayActive", {params: data}),
  todayInvitation: (data = {}) => request.get("/open/todayInvitation", {params: data}),
  weekActiveSales: (data = {}) => request.get("/open/weekActiveSales", {params: data}),
  weekInvitation: (data = {}) => request.get("/open/weekInvitation", {params: data}),
  weekActiveShop: (data = {}) => request.get("/open/weekActiveShop", {params: data}),
  salesCount: (data = {}) => request.get("/open/sales_count", {params: data}),
  personShopCount: (data = {}) => request.get("/open/personShopCount", {params: data}),
  personOrderCount: (data = {}) => request.get("/open/personOrderCount", {params: data}),
  personTodayHasOrder: (data = {}) => request.get("/open/personTodayHasOrder", {params: data}),
  personTodayOrderCount: (data = {}) => request.get("/open/personTodayOrderCount", {params: data}),
  personOrderTodayViews: (data = {}) => request.get("/open/personOrderTodayViews", {params: data}),
  map: (data = {}) => request.get("/open/shopAddress", {params: data}),
};
export {api, shop, amap,largeDataScreen}