import React from "react";
import { Button, Dropdown, Menu, Space, Tooltip, Typography } from "@arco-design/web-react";
import { IconMoonFill, IconSunFill } from "@arco-design/web-react/icon";
import { useDispatch, useSelector } from "react-redux";
import { ReducerState } from "@/redux";
import useLocale from "@/utils/useLocale";
import Logo from "@/assets/logo.svg";
import history from "@/history";

import styles from "./style/index.module.less";

function Navbar() {
  const locale = useLocale();
  const theme = useSelector((state: ReducerState) => state.global.theme);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    history.push("/login");
  }

  function onMenuItemClick(key) {
    if (key === "logout") {
      logout();
    }
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <Space size={8}>
          <Logo />
          <Typography.Title style={{ margin: 0, fontSize: 18 }} heading={5}>
            订个包间
          </Typography.Title>
        </Space>
      </div>
      <ul className={styles.right}>
        <li>
          <Tooltip
            content={
              theme === "light"
                ? locale["settings.navbar.theme.toDark"]
                : locale["settings.navbar.theme.toLight"]
            }
          >
            <Button
              type="text"
              icon={theme === "light" ? <IconMoonFill /> : <IconSunFill />}
              onClick={() =>
                dispatch({
                  type: "toggle-theme",
                  payload: { theme: theme === "light" ? "dark" : "light" },
                })
              }
              style={{ fontSize: 20 }}
            />
          </Tooltip>
        </li>
        {userInfo && (
          <li>
            <Dropdown
              trigger="click"
              droplist={
                <Menu onClickMenuItem={onMenuItemClick}>
                  <Menu.Item key="logout">退出</Menu.Item>
                </Menu>
              }
            >
              <Typography.Text className={styles.username}>{userInfo.username}</Typography.Text>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
