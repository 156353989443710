/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-09-25 20:05:34
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-10-12 16:11:51
 * @Description: 
 */
import axios, {AxiosResponse} from "axios";
import {Message} from "@arco-design/web-react";
import history from "@/history";

const ACCESS_TOKEN = "token";
const api_url = {
  "product": "https://api.dinggebaojian.com/api",
  "dev": "https://djbj.deer.funengyun.cn/api",
};
const baseURL = process.env.NODE_ENV === "development" ? api_url.dev : api_url.product;
const request = axios.create({
  baseURL,
  headers: {
    "content-type": "application/json",
  },
  timeout: 60000,
});
export {baseURL}

request.interceptors.request.use((config) => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  token && (config.headers[ACCESS_TOKEN] = token);
  return config;
});

interface resType {
  code: number
  msg?: string
  message?: string
}

declare module "axios" {
  interface AxiosResponse extends resType {}
}

export interface Res extends AxiosResponse {
  code: number
  msg?: string
  message?: string
}

request.interceptors.response.use((response) => {
  const err_msg = response.data.msg || "服务器错误";
  if (response.data.code === -9999) {
    Message.error(err_msg);
    setTimeout(() => {
      history.push("/login");
    }, 1000)
    return;
  }
  return response.data as Res;
});
export default request;