import {Button, Form, Input, Space} from "@arco-design/web-react";
import {FormInstance} from "@arco-design/web-react/es/Form";
import {IconLock, IconUser} from "@arco-design/web-react/icon";
import React, {useRef, useState} from "react";
import styles from "./style/index.module.less";
import {api} from "@/redux/api";
import history from "@/history";
import {Res} from "@/utils/request";

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  function afterLoginSuccess() {
    window.location.href = history.createHref({
      pathname: '/statistics',
    });
  }

  function login(params) {
    setErrorMessage('');
    setLoading(true);
    api.login(params).then((res: Res) => {
      if (res.code === 0){
        localStorage.setItem("token",res.data.token);
        localStorage.setItem("user",JSON.stringify(res.data.user));
        afterLoginSuccess();
      }else{
        // @ts-ignore
        setErrorMessage(res.msg)
      }
      setLoading(false);
    })
  }

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>登录</div>
      <div className={styles['login-form-sub-title']}>餐饮包间预订管理平台</div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form className={styles['login-form']} layout="vertical" ref={formRef}>
        <Form.Item field="username" rules={[{ required: true, message: '用户名不能为空' }]}>
          <Input prefix={<IconUser />} onPressEnter={onSubmitClick} />
        </Form.Item>
        <Form.Item field="password" rules={[{ required: true, message: '密码不能为空' }]}>
          <Input.Password
            prefix={<IconLock />}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            登录
          </Button>
        </Space>
      </Form>
    </div>
  );
}
